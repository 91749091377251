import React from "react";
import { Button, Input, Switch, Tooltip } from "antd";
import { ErrorMessage, Field, Form } from "formik";
import { getPermissionStyle } from "../../redux/slice/permissions/permissionUtils";
import PasswordManagerLoginAttempt from "./PasswordManagerLoginAttempt";
import { getStrengthMessage } from "./PasswordManagerHelper";
import { useTranslation } from "react-i18next";

const PasswordManagerForm = (props: any) => {
  const {
    handleSubmit,
    setFieldValue,
    handleChange,
    isSubmitting,
    passwordExpiry,
    setPasswordExpiry,
    passwordLength,
    setPasswordLength,
    passwordLengthAction,
    setPasswordLengthAction,
    lowercasePassword,
    uppercasePassword,
    specialCharactersPassword,
    digitsPassword,
    recentlyUsedPassword,
    values,
    hasUpdatePermission,
  } = props;
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit} className="w-full">
      <div className="w-full flex password-page flex-wrap">
        <div className="w-1/2 password-left">
          <div className="flex w-full  justify-between relative mb-10">
            <p className=" font-Inter w-1/2">
              {t("passwordManager.passwordExpiry")}
              <Tooltip title={t("passwordManager.passwordValidityDescription")}>
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-1/2 flex justify-end select-none">
              <div className="increment">
                <Field
                  as={Input}
                  type="number"
                  className="input-field pointer-events-none"
                  name="passwordExpiry"
                  value={passwordExpiry}
                  onChange={(e: any) => setFieldValue("passwordExpiry", e.target.value)}
                  data-testid="passwordExpiry-input"
                />
                <span
                  role="none"
                  className={`material-symbols-outlined remove ${passwordExpiry === 0 && "decrement-gray  pointer-events-none"}`}
                  onClick={() => {
                    setPasswordExpiry(passwordExpiry - 15);
                    setFieldValue("passwordExpiry", Math.max(0, values.passwordExpiry - 15));
                  }}
                  data-testid="remove-count"
                >
                  remove
                </span>
                <span
                  role="none"
                  className={`material-symbols-outlined add  ${passwordExpiry === 90 && "pointer-events-none decrement-gray"}`}
                  onClick={() => {
                    setPasswordExpiry(passwordExpiry + 15);
                    setFieldValue("passwordExpiry", values.passwordExpiry + 15);
                  }}
                  data-testid="add-count"
                >
                  add
                </span>
              </div>
              <ErrorMessage name="passwordExpiry" component="div" className="error-message" />
            </div>
          </div>
          <div className="flex w-full  justify-between relative mb-8">
            {/* word-break: break-word; white-space: normal; */}
            <p className=" font-Inter w-[50%]  whitespace-normal break-words !text-wrap" data-testid="password-min-length">
              {t("passwordManager.passwordMinimumLength")}
              <Tooltip title={t("passwordManager.minimumCharactersDescription")}>
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-1/2 flex justify-end select-none">
              <div className="increment">
                <Field
                  as={Input}
                  type="number"
                  className="input-field pointer-events-none"
                  name="passwordLength"
                  value={passwordLength}
                  onChange={(e: any) => setFieldValue("passwordLength", e.target.value)}
                  data-testid="passwordLength-input"
                />
                <span
                  data-testid="password-length-decrement-btn"
                  role="none"
                  className={`material-symbols-outlined remove   ${passwordLength > 8 && "length"} ${passwordLength === 8 && "pointer-events-none decrement-gray"}`}
                  onClick={() => {
                    setPasswordLength(passwordLength - 1);
                    setFieldValue("passwordLength", Math.max(0, values.passwordLength - 1));
                    setPasswordLengthAction(true);
                  }}
                >
                  remove
                </span>
                <span
                  data-testid="password-length-increment-btn"
                  role="none"
                  className={`material-symbols-outlined add  ${passwordLength > 8 && "length"} ${passwordLength === 13 && "pointer-events-none decrement-gray"}`}
                  onClick={() => {
                    setPasswordLength(passwordLength + 1);
                    setFieldValue("passwordLength", values.passwordLength + 1);
                    setPasswordLengthAction(true);
                  }}
                >
                  add
                </span>
                {passwordLengthAction ? (
                  <h6 className={`mt-1 strength-${getStrengthMessage(t, passwordLength)} length-${passwordLength}`}>
                    {getStrengthMessage(t, passwordLength)}
                  </h6>
                ) : (
                  <h6 className={`mt-2 opacity-0 strength-${getStrengthMessage(t, passwordLength)} length-${passwordLength}`}>
                    {getStrengthMessage(t, passwordLength) !== "" ? getStrengthMessage(t, passwordLength) : ""}
                  </h6>
                )}
              </div>
              <ErrorMessage name="passwordLength" component="div" className="error-message" />
            </div>
          </div>
          <div className="flex w-full  justify-between relative mb-8">
            <p className=" font-Inter w-[70%]" data-testid="uppercase-characters">
              {t("passwordManager.uppercaseCharacters")}
              <Tooltip title={t("passwordManager.uppercaseRequirement")}>
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-[30%] flex justify-end">
              <Field
                onChange={(e: any) => handleChange(e, "uppercase", setFieldValue)}
                as={Switch}
                checked={uppercasePassword}
                type="text"
                className=""
                name="uppercase"
                data-testid="uppercase-input"
              />
              <ErrorMessage name="uppercase" component="div" className="error-message" />
            </div>
          </div>
          <div className="flex w-full  justify-between relative mb-8">
            <p className=" font-Inter w-[70%]">
              {t("passwordManager.lowercaseCharacters")}
              <Tooltip title={t("passwordManager.lowercaseRequirement")}>
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-[30%] flex justify-end">
              <Field
                checked={lowercasePassword}
                onChange={(e: any) => handleChange(e, "lowercase", setFieldValue)}
                as={Switch}
                type="text"
                className=""
                name="lowercase"
                data-testid="lowercase-input"
              />
              <ErrorMessage name="lowercase" component="div" className="error-message" />
            </div>
          </div>
          <div className="flex w-full  justify-between relative mb-8">
            <p className=" font-Inter w-[70%]">
              {t("passwordManager.specialCharacters")}
              <Tooltip title={t("passwordManager.specialCharacterRequirement")}>
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-[30%] flex justify-end">
              <Field
                checked={specialCharactersPassword}
                onChange={(e: any) => handleChange(e, "specialCharacter", setFieldValue)}
                as={Switch}
                type="text"
                className=""
                name="specialCharacter"
                data-testid="specialCharacter-input"
              />
              <ErrorMessage name="specialCharacter" component="div" className="error-message" />
            </div>
          </div>
        </div>
        <div className="w-1/2 password-left mt-[11px]">
          <div className="flex w-full  justify-between relative mb-14">
            <p className=" font-Inter w-[70%]">
              {t("passwordManager.digits")}
              <Tooltip title={t("passwordManager.numericalDigitRequirement")}>
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </p>
            <div className="w-1/2 flex justify-end">
              <Field
                checked={digitsPassword}
                onChange={(e: any) => handleChange(e, "digit", setFieldValue)}
                as={Switch}
                type="text"
                className=""
                name="digit"
                data-testid="digit-input"
              />
              <ErrorMessage name="digit" component="div" className="error-message" />
            </div>
          </div>
          <div className="flex w-full  justify-between relative mb-8">
            <p className=" font-Inter  flex-col note">
              <span className="not"> {t("passwordManager.notRecentlyUsed")}</span>
              <span className="w-full text-wrap">
                <strong>{t("common.note")}:</strong> {t("passwordManager.recentPasswordReuseWarning")}
              </span>
            </p>
            <div className="note-switch flex justify-end">
              <Field
                checked={recentlyUsedPassword}
                onChange={(e: any) => handleChange(e, "recentlyUsed", setFieldValue)}
                as={Switch}
                type="text"
                className=""
                name="recentlyUsed"
                data-testid="recentlyUsed-input"
              />
              <ErrorMessage name="recentlyUsed" component="div" className="error-message" />
            </div>
          </div>
          <PasswordManagerLoginAttempt setFieldValue={setFieldValue} values={values} />
        </div>
      </div>
      <div className="w-full flex justify-end pb-10 pt-10 password-footer">
        <Button type="primary" onClick={isSubmitting} className={`btn btn-secondary w-auto min-w-[186px] ${getPermissionStyle(hasUpdatePermission)}`}>
          {t("passwordManager.restoreDefault")}
        </Button>
        <Button type="primary" htmlType="submit" className={`btn mr-12 ${getPermissionStyle(hasUpdatePermission)}`}>
          {t("common.save")}
        </Button>
      </div>
    </Form>
  );
};
export default PasswordManagerForm;
