import React, { useEffect, useState } from "react";
import CardTitle from "../../component/CardTitle";
import batch from "../../../assets/images/icons/apps.png";
import ReactApexChart from "react-apexcharts";
import { Select, Empty } from "antd";
import { ApexOptions } from "apexcharts";
import ImageView from "../../component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import { useSelector } from "react-redux";
import ApplicationDetailsModal from "./ApplicationDetailsModal";
import "./Application.scss";
import { useTranslation } from "react-i18next";

const Application = () => {
  const { t } = useTranslation();
  const appsData = useSelector((state: any) => state?.dashboardDetails?.data?.applicationAssignedUserDetailsDto);
  const data = appsData?.applicationAssignedUserDetailsCountList;
  const [series, setSeries] = useState<any>([{ data: [] }]);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedApplication, setSelectedApplication] = useState<any>();
  const [key, setKey] = useState(1);
  const [filter, setFilter] = useState("ascending");
  const dropDownList = [
    { label: t("common.ascending"), value: "ascending" },
    { label: t("common.descending"), value: "descending" },
  ];

  const [options, setOptions] = React.useState<ApexOptions>({
    plotOptions: {
      bar: {
        borderRadius: 10,
        horizontal: true,
        barHeight: "24",
        borderRadiusApplication: "end",
        dataLabels: {
          total: {
            enabled: false,
            offsetX: -30,
            style: {
              fontSize: "13px",
              fontWeight: 900,
              color: "#ffffff",
            },
          },
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -12,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    colors: ["#5441DA"],
    legend: { show: false },
    chart: {
      type: "bar",
      height: 430,
      stacked: true,
      toolbar: {
        tools: {
          download: false,
        },
      },
      events: {
        dataPointSelection: (event, chartContext, { dataPointIndex, seriesIndex, w }) => {
          const selectedCategory = chartContext?.w?.config?.xaxis?.categories[dataPointIndex];
          setSelectedApplication(selectedCategory);
          setIsActive(true);
        },
      },
    },
    xaxis: {
      categories: [],
      labels: {
        show: false,
      },
      axisBorder: {
        show: true,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (data) {
      const sortedData = [...data]?.sort((a, b) => a?.totalCount - b?.totalCount);
      const topFiveElements = sortedData?.slice(0, 5);
      const nameArray = topFiveElements?.map((item) => item?.name);
      const totalCount = topFiveElements?.map((item) => item?.totalCount);
      const allTotalCountsAreZero = data?.every((item: any) => item !== null && item?.totalCount === 0);
      setSeries(allTotalCountsAreZero ? [] : [{ data: totalCount }]);
      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: [...nameArray],
        },
        yaxis: {
          labels: {
            show: true,
            align: "right",
            offsetY: totalCount?.length === 1 ? -8 : 0,
          },
        },
      }));
    }
  }, [appsData]);

  const handleChangeUseTypes = (value: string) => {
    setFilter(value);
    const sortData = (ascending: boolean) => {
      let sortedData = [...(appsData?.applicationAssignedUserDetailsCountList ?? [])].sort((a, b) => {
        return ascending ? a?.totalCount - b?.totalCount : b?.totalCount - a?.totalCount;
      });
      const topFiveElements = sortedData?.slice(0, 5) || [];
      const nameArray = topFiveElements?.map((item: any) => item?.name);
      const totalCount = topFiveElements?.map((item: any) => item?.totalCount);
      setSeries([{ data: totalCount }]);
      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: [...nameArray],
        },
      }));
    };

    if (value === "ascending") {
      sortData(true);
    } else {
      sortData(false);
    }

    setKey((prevKey) => prevKey + 1);
  };
  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <div className="_card  w-full rounded-lg __application">
      <div className="_card-header --no-border ">
        <div className="title">
          <CardTitle title="Applications" src={batch} />
        </div>
        <div>
          <Select
            disabled={series.length === 0}
            suffixIcon={<ImageView className="w-6 custom-chevron" src={images.chevronDown} alt="chevronDown" />}
            className="w-[180px] h-[48px] mr-3"
            onChange={(value) => {
              handleChangeUseTypes(value);
            }}
            showSearch
            value={filter}
          >
            {dropDownList?.map((option: any) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>

      <div className="px-10 application-chart h-[410px] mr-5" key={key}>
        {series.length !== 0 ? (
          <ReactApexChart className="bar-chart" options={options} series={series} type="bar" height={350} />
        ) : (
          <div className="h-full flex justify-center items-center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
      {isActive && <ApplicationDetailsModal isActive={isActive} handleClose={handleClose} selectedApplicationName={selectedApplication} selectedApplication={selectedApplication} />}
    </div>
  );
};
export default Application;
