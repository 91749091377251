import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { retrieveData } from "../../services/storage/Storage";
import { setTenantActiveStep } from "../../redux/slice/CustomerSlice";
import {
  setApplication,
  setCustomerTableData,
  setMFA,
  setRecoveryAttempts,
  setRiskAssessmentData,
  setTotalLicenses,
} from "../../redux/slice/DashboardSlice";
import { startCase } from "lodash";

const TenantHeader = () => {
  const dispatch = useDispatch();
  const realmName = retrieveData("realmName", true);
  const tenantActiveStep = useSelector((state: any) => state?.CustomerSlice?.tenantActiveStep);
  const tenantAdminDisplayName = useSelector((state: any) => state?.CustomerSlice?.tenantAdminDisplayName);
  const tenantsCustomerDisplayName = useSelector((state: any) => state?.CustomerSlice?.tenantsCustomerDisplayName);

  const userProfileDetails = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleEditAdminDetails = () => {
    navigate("/edit-tenant");
  };

  const handleKerberosNavigate = (step?: number) => {
    dispatch(setCustomerTableData([]));
    dispatch(setRiskAssessmentData([]));
    dispatch(setApplication([]));
    dispatch(setMFA([]));
    dispatch(setRecoveryAttempts([]));
    dispatch(setTotalLicenses({}));

    if (step === 1) {
      dispatch(setTenantActiveStep(step));
      navigate("/tenant-admin-dashboard");
    } else if (step === 0) {
      dispatch(setTenantActiveStep(0));
      navigate("/admin-dashboard");
    }
  };

  return (
    <div className="flex w-full edit-admin absolute min-h-[90px] justify-between px-16 z-[1]" data-testid="tenant-header">
      <div className="tenant-header-breadcrumb" data-testid="tenant-breadcrumb">
        <ul className="flex items-center">
          <li role="none" className="text-lg font-normal text-[#38383A] font-Inter cursor-pointer" onClick={() => handleKerberosNavigate(0)}>
            {startCase(realmName)} {"> "}
          </li>
          <li
            className={`pl-2 text-lg  ${tenantActiveStep > 1 ? "font-normal text-[#38383A] font-Inter cursor-pointer" : "font-semibold text-[#000] font-Inter"}`}
            onClick={() => handleKerberosNavigate(1)}
            role="none"
          >
            {tenantAdminDisplayName} {tenantActiveStep > 1 && ">"}
          </li>
          {tenantActiveStep === 2 && <li className="pl-2 text-lg font-semibold text-[#000] font-Inter">{tenantsCustomerDisplayName}</li>}
        </ul>
        <div className="text-[26px] mt-2 font-semibold text-[#000] font-Inter" data-testid="tenant-display-name">
          {tenantActiveStep === 2 ? tenantsCustomerDisplayName : tenantAdminDisplayName}
        </div>
      </div>
      {(userProfileDetails?.customerType === "MASTER" || userProfileDetails?.customerType === "MSSP") && (
        <div className="flex gap-x-2 items-center">
          <p>{t("editDetailsPrompt")}</p>
          <Button
            type="primary"
            className=" ml-4 flex items-center justify-evenly font-semibold text-[18px] font-Inter w-[115px] h-[40px] bg-[#5441DA] text-white rounded-full"
            size="large"
            onClick={handleEditAdminDetails}
          >
            {t("details")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TenantHeader;
