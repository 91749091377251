import React, { useEffect, useState } from "react";
import ImageView from "../../../../layouts/component/ImageView";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { storeData } from "../../../../services/storage/Storage";
import DeleteApps from "../modal/DeleteApps";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { setAppConfigDetails } from "../../../../redux/slice/app-store/AppDetailsSlice";
import { hasPermission } from "../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../const/RolePermissions";
import PermissionsModal from "../../../../layouts/permissionsModal/PermissionsModal";
import NewAppConfiguration from "../modal/new-app-configure/NewAppConfiguration";
import CardOption from "./card-option/CardOption";
import { displayAppLogo, displayAppName, displayDescription } from "./MyAppCardHelper";
import ProvisioningCardOption from "./card-option/ProvisioningCardOption";
import SSOCardOption from "./card-option/SSOCardOption";
import { setConfigurationFlow, setIsConfigureButtonPressed } from "../../../../redux/slice/app-store/appConfigureSlice";
import { setAppRender } from "../../../../redux/slice/appsRenderSlice";

import "./Card.scss";
import { cursorNotAllowedStyle } from "../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import Loader from "../../../../layouts/component/Loader";

const MyAppCard = (props: any) => {
  const { t, i18n } = useTranslation();

  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);
  const userProfileDetails = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);
  const isMaster = userProfileDetails?.customerType === "MASTER";

  const hasEditAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "update");
  const hasCreateAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "create");

  const hasCreateProvisioningPermission = permissions && hasPermission(permissions, modulePermissions.provisioning, "create");
  const createAccess = hasCreateProvisioningPermission || hasCreateAppStorePermission;

  const { myAppDetails, masterData, available, getProvisioningAppList, provisioningLists, setLoader } = props;
  const clientDisplayConsole = useGetApiRequests("clientDisplayConsole", "PUT");
  const [appDetails, setAppDetails] = useState([]);
  const [myAllAppDetails, setMyAppDetails] = useState([]);
  const [currentAppDetails, setCurrentAppDetails] = useState([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);
  const [appData, setAppData] = useState<any>(null);

  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.available) {
      setAppDetails(masterData);
    } else {
      setMyAppDetails(myAppDetails);
    }
  }, [props]);

  const ConfigureSingleApp = () => {
    if (hasCreateAppStorePermission) {
      if (appData !== null) {
        dispatch(setAppConfigDetails(appData));
      }
      storeData("configureApp", "configure", true);
      navigate("/app-store/configure-app");
    } else {
      setPermissionsModal(true);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleToggle = (item: any, index: any, event: any) => {
    // Create a new array with updated item
    if (hasEditAppStorePermission) {
      // const updatedItems: any = myAllAppDetails.map((detail: any, idx: any) => {
      //   if (idx === index) {
      //     return {
      //       ...detail,
      //       client: {
      //         ...detail.client,
      //         alwaysDisplayInConsole: !detail.client.alwaysDisplayInConsole, // Toggle the value
      //       },
      //     };
      //   }
      //   return detail;
      // });

      const queryParams = {
        "akku-client-id": item.akkuClientDto.akkuClientId,
        "is-display-in-console": event,
      };
      clientDisplayConsole("", queryParams)
        .then((response: any) => {
          if (response.status === 200) {
            triggerNotification("success", "", response?.data?.message, "topRight");
            dispatch(setAppRender(!reInitiateApps));
          }
          // setMyAppDetails(updatedItems);
        })
        .catch((err: any) => {
          handleRequestError(err);
        });
    } else {
      setPermissionsModal(true);
    }
  };

  const handelCreateNewApp = (item: any) => {
    dispatch(setConfigurationFlow(true));

    if (hasCreateAppStorePermission || hasCreateProvisioningPermission) {
      if (item !== null) {
        setAppData(item);
      } else {
        setAppData(null);
      }
      storeData("currentAppName", item?.name, true);
      dispatch(setAppConfigDetails(item));
      dispatch(setIsConfigureButtonPressed(true));
      setOpenPopUp(true);
    } else {
      setPermissionsModal(true);
    }
  };

  const handleModalClose = () => {
    setOpenPopUp(false);
  };

  const handleCloseHelpModal = () => {
    setPermissionsModal(false);
  };

  return (
    <>
      <div className="w-full flex flex-wrap">
        {appDetails && available ? (
          appDetails.length > 0 &&
          appDetails.map((item: any, index: number) => (
            <div className="2xl:w-1/4  lg:w-1/3 sm:w-1/2  mt-4" key={item.akkuMasterClientId}>
              <div className="mx-4 min-h-[180px] max-h-[180px] mb-8 relative border rounded-xl flex flex-col app-card overflow-hidden">
                <div className="flex min-h-[129px] max-h-[129px] items-center px-5 gap-3">
                  <div className="max-h-[67px] max-w-[67px]  border rounded-[9px] min-h-[67px] min-w-[67px] flex justify-center items-center ">
                    <ImageView src={item.logoUrl} alt="my apps" className=" w-[60px] h-[60px] my-apps-icon" />
                  </div>
                  <div className="">
                    <p className="text-[#000] mb-[2px] capitalize text-[20px] font-Inter font-semibold my-app-card-name ">{item.name}</p>
                    <Tooltip title={item.description}>
                      <p className="text-[#444] text-[16px] font-Inter card-description">{item.description}</p>
                    </Tooltip>
                  </div>
                </div>
                <div onClick={() => handelCreateNewApp(item)} className="card-action">
                  <p role="none" className={`configure font-Inter ${cursorNotAllowedStyle(createAccess)}`}>
                    {t("appManagement.setup")}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            {myAllAppDetails?.map((item: any, index: number) => (
              <div className="2xl:w-1/4  lg:w-1/3 sm:w-1/2" key={item?.akkuClientId}>
                <div className="mx-4 h-[245px] mb-8 relative border rounded-xl flex flex-col app-card  app-card-box bg-[#F3F3F8]">
                  {isMaster && item?.akkuProvisioningConnectorDto?.publishStatus && (
                    <div className="absolute left-[16px] top-[-13px]">
                      <p className={`app-configure-btn-published font-Inter text-[#1C61DC] bg-[#DCE9FF]`} role="none">
                        {t("appManagement.published")}
                      </p>
                    </div>
                  )}
                  <div className="flex min-h-[129px] max-h-[129px] items-center px-5 gap-3 bg-[#fff] rounded-xl border">
                    <div className="max-h-[67px] max-w-[67px]  border rounded-[9px] min-h-[67px] min-w-[67px] flex justify-center items-center ">
                      <ImageView
                        src={item?.logoUrl ? imageBaseUrl + item?.logoUrl : displayAppLogo(item)}
                        alt="my apps"
                        className=" w-[60px] h-[60px] my-apps-icon"
                      />
                      <CardOption
                        item={item}
                        ConfigureSingleApp={ConfigureSingleApp}
                        setPermissionsModal={setPermissionsModal}
                        getProvisioningAppList={getProvisioningAppList}
                        setLoader={setLoader}
                      />
                    </div>

                    <div className="">
                      <p className="text-[#000] mb-[2px] capitalize text-[20px] font-Inter font-semibold my-app-card-name ">{displayAppName(item)}</p>
                      <Tooltip title={displayDescription(item)}>
                        <p className="text-[#444] text-[16px] font-Inter card-description">{displayDescription(item)}</p>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="w-full h-full">
                    <div className="flex justify-between  border-b py-[12px] px-5 max-h-[55px] items-center">
                      <div className="max-w-[50%] sso-provisioning-label-container">
                        <p
                          className={`text-[#151515] font-Inter font-medium text-[18px] sso-provisioning-label 
              2xl:${i18n.language !== "en" ? "text-[16px]" : "text-[18px]"}`}
                        >
                          {t("appManagement.singleSignOn")}
                        </p>
                      </div>
                      <div className="relative sso-card-option-container self-start">
                        <SSOCardOption handleToggle={handleToggle} item={item} index={index} />
                      </div>
                    </div>
                    <div className="flex justify-between py-[12px] px-5 max-h-[55px] items-center">
                      <div className="max-w-[50%] sso-provisioning-label-container">
                        <p
                          className={`text-[#151515] font-Inter font-medium text-[18px] sso-provisioning-label 
              2xl:${i18n.language !== "en" ? "text-[16px]" : "text-[18px]"}`}
                        >
                          {t("appManagement.provisioningLabel")}
                        </p>
                      </div>
                      <div className="relative top-[-4px] left-[-3px] provisioning-card-option-container self-start">
                        <ProvisioningCardOption
                          handelCreateNewApp={handelCreateNewApp}
                          item={item}
                          index={index}
                          provisioningLists={provisioningLists}
                          setLoader={setLoader}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handleCloseHelpModal} />}
      {openPopUp && (
        <NewAppConfiguration
          openPopUp={openPopUp}
          handleModalClose={handleModalClose}
          ConfigureSingleApp={ConfigureSingleApp}
          hasCreateAppStorePermission={hasCreateAppStorePermission}
          setPermissionsModal={setPermissionsModal}
        />
      )}
      {openModal && <DeleteApps openModal={openModal} handleClose={handleClose} currentAppDetails={currentAppDetails} />}
    </>
  );
};

export default MyAppCard;
